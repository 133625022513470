<template>
  <div
    v-if="blok.url"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    :class="[...marginClasses, containerClasses]"
  >
    <Intersect :threshold="0.5" @enter="intersectPromotionView">
      <VideoEmbed
        :url="url"
        :o-embed="oEmbed"
        allow="autoplay;
    encrypted-media"
      />
    </Intersect>
  </div>
</template>

<script setup lang="ts">
import { useStoryblokMargins } from '../composables/useStoryblokMargins'
import type { SbVimeo } from '../types/storyblok'
import { useOEmbed } from '~/composables/useOEmbed'

const props = defineProps({
  blok: {
    type: Object as PropType<SbVimeo>,
    required: true,
  },
})

const { trackPromotionView } = await useTrackingEvents()
const { containerClasses, marginClasses } = useStoryblokMargins(props.blok)
const videoId = computed(() => {
  const [, id] =
    /.*(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*).*$/.exec(
      (props as any)?.blok?.url,
    ) || []
  return id
})

const { data: oEmbed } = await useOEmbed({
  params: { url: props.blok.url || '', platform: 'vimeo' },
  key: `vimeo-oembed-${props.blok.url}`,
})

const url = computed(() => {
  const url = new URL(`https://player.vimeo.com/video/${videoId.value}`)
  url.searchParams.append('title', '0')
  url.searchParams.append('byline', '0')
  url.searchParams.append('controls', props.blok.controls ? '1' : '0')
  url.searchParams.append('autoplay', props.blok.autoplay ? '1' : '0')
  if (props.blok.autoplay) {
    url.searchParams.append('muted', '1')
  }
  if (props.blok.loop) {
    url.searchParams.append('loop', '1')
  }
  if (props.blok.h) {
    url.searchParams.append('h', props.blok.h)
  }
  return url.toString()
})

const intersectPromotionView = (
  _: IntersectionObserverEntry,
  stop: () => void,
) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}
</script>
